import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

export type NumerologyResult = {
    wayOfLife: number[]
    angularStone: number[]
    hereditary: number[]
    personalYear: number[]
    firstName1: {
        active: number[]
        intimate: number[]
        realization: number[]
        expression: number[]
    }
    allFirstNames: {
        active: number[]
        intimate: number[]
        realization: number[]
        expression: number[]
    }
    inclusionTables: {
        shortName: number[]
        fullName: number[]
    }
}
function createEmptyResult(): NumerologyResult {
    return {
        wayOfLife: [],
        angularStone: [],
        hereditary: [],
        personalYear: [],
        firstName1: {
            active: [],
            intimate: [],
            realization: [],
            expression: [],
        },
        allFirstNames: {
            active: [],
            intimate: [],
            realization: [],
            expression: [],
        },
        inclusionTables: {
            shortName: new Array(9).fill(0),
            fullName: new Array(9).fill(0),
        },
    }
}
type State = {
    dayOfBirth: string
    lastName: string
    firstName1: string
    firstName2: string
    firstName3: string
    firstName4: string
    results: NumerologyResult
}

type Actions = {
    setLastName(lastName: string): void
    setDayOfBirth(dayOfBirth: string): void
    setFirstName1(firstName: string): void
    setFirstName2(firstName: string): void
    setFirstName3(firstName: string): void
    setFirstName4(firstName: string): void
}

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
const vowels = ['A', 'E', 'I', 'O', 'U', 'Y']

export function removeDiacritics(str: string) {
    return str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

function getNumberForLetter(letter: string) {
    const normalized = removeDiacritics(letter).toUpperCase()
    if (!alphabet.includes(normalized)) return 0
    return (alphabet.indexOf(normalized) % 9) + 1
}

function computeIntermediateNumbers(num: number) {
    if (num < 10) return [num]
    const values: number[] = [num]
    while (values[values.length - 1] >= 10) {
        // Sum the digits composing the number
        values.push(
            values[values.length - 1]
                .toString()
                .split('')
                .reduce((a, b) => a + parseInt(b, 10), 0),
        )
    }
    return values
}

function computeInclusionTable(name: string) {
    const inclusionTable = new Array(9).fill(0)
    for (const char of name.split('')) {
        const nb = getNumberForLetter(char)
        inclusionTable[nb - 1] += 1
    }
    return inclusionTable
}

function computeResultsForState(state: State): NumerologyResult {
    const results: NumerologyResult = createEmptyResult()
    // Compute way of life
    const [year, month, day] = state.dayOfBirth.split('-')
    if (year && month && day) {
        results.wayOfLife = computeIntermediateNumbers(
            [year, month, day]
                .flatMap((n) => n.split(''))
                .map((n) => parseInt(n, 10))
                .reduce((a, b) => a + b, 0),
        )
    }
    const allFirstNames = [state.firstName1, state.firstName2, state.firstName3, state.firstName4]
        .flatMap((firstName) => (firstName ? [firstName] : []))
        .join('')
    // Compute angular stone
    if (state.firstName1) {
        results.angularStone = computeIntermediateNumbers(getNumberForLetter(state.firstName1[0]))
    }
    // Compute active number
    if (state.firstName1) {
        results.firstName1.active = computeIntermediateNumbers(
            state.firstName1.split('').reduce((a, b) => a + getNumberForLetter(b), 0),
        )
        results.allFirstNames.active = computeIntermediateNumbers(
            allFirstNames.split('').reduce((a, b) => a + getNumberForLetter(b), 0),
        )
    }
    // Compute hereditary number
    if (state.lastName.length > 0) {
        results.hereditary = computeIntermediateNumbers(
            state.lastName.split('').reduce((a, b) => a + getNumberForLetter(b), 0),
        )
    }
    // Compute intimate and realization number
    if (state.firstName1 && state.lastName.length > 0) {
        let fullName = (state.firstName1 + state.lastName).split('').map(removeDiacritics)
        results.firstName1.intimate = computeIntermediateNumbers(
            fullName
                .filter((letter) => vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        results.firstName1.realization = computeIntermediateNumbers(
            fullName
                .filter((letter) => !vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        fullName = (allFirstNames + state.lastName).split('').map(removeDiacritics)
        results.allFirstNames.intimate = computeIntermediateNumbers(
            fullName
                .filter((letter) => vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
        results.allFirstNames.realization = computeIntermediateNumbers(
            fullName
                .filter((letter) => !vowels.includes(letter.toUpperCase()))
                .map((n) => getNumberForLetter(n))
                .reduce((a, b) => a + b, 0),
        )
    }
    // Compute expression number
    const [hereditary] = results.hereditary.slice(Math.max(results.hereditary.length - 2, 0))
    if (results.firstName1.active.length > 0 && results.hereditary.length > 0) {
        const [activeFirstName] = results.firstName1.active.slice(
            Math.max(results.firstName1.active.length - 2, 0),
        )
        results.firstName1.expression = computeIntermediateNumbers(activeFirstName + hereditary)
        const [activeAllFirstNames] = results.allFirstNames.active.slice(
            Math.max(results.allFirstNames.active.length - 2, 0),
        )
        results.allFirstNames.expression = computeIntermediateNumbers(
            activeAllFirstNames + hereditary,
        )
    }
    // Compute personal year
    if (day && month) {
        const currentYear = new Date().getFullYear()
        results.personalYear = computeIntermediateNumbers(
            [day, month, currentYear.toString()]
                .flatMap((n) => n.split(''))
                .map((n) => parseInt(n, 10))
                .reduce((a, b) => a + b, 0),
        )
    }
    // Compute inclusion tables
    results.inclusionTables = {
        shortName: computeInclusionTable(state.firstName1 + state.lastName),
        fullName: computeInclusionTable(allFirstNames + state.lastName),
    }
    return results
}

export const useNumerologyStore = create<State & Actions>()(
    immer((set) => ({
        dayOfBirth: '',
        lastName: '',
        firstName1: '',
        firstName2: '',
        firstName3: '',
        firstName4: '',
        results: createEmptyResult(),
        setLastName: (lastName) =>
            set((state) => {
                state.lastName = lastName
                state.results = computeResultsForState(state)
            }),
        setDayOfBirth: (dayOfBirth) =>
            set((state) => {
                state.dayOfBirth = dayOfBirth
                state.results = computeResultsForState(state)
            }),
        setFirstName1: (firstName1) =>
            set((state) => {
                state.firstName1 = firstName1
                state.results = computeResultsForState(state)
            }),
        setFirstName2: (firstName2) =>
            set((state) => {
                state.firstName2 = firstName2
                state.results = computeResultsForState(state)
            }),
        setFirstName3: (firstName3) =>
            set((state) => {
                state.firstName3 = firstName3
                state.results = computeResultsForState(state)
            }),
        setFirstName4: (firstName4) =>
            set((state) => {
                state.firstName4 = firstName4
                state.results = computeResultsForState(state)
            }),
    })),
)
